import React, { Component }  from 'react';
import discord from './discord.svg';
import whale from './whale.svg';
import './App.css';



function App() {
  return (
    <div className="App">
    <header className="App-header">
    
        <img src={whale } className="App-logo" alt="logo" /> 
        <h2>
               </h2>
        
      </header>
    </div>
  );
}

export default App;
